import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

//assets
import profile from '../images/profile.png'

//styles
import styled, { keyframes } from 'styled-components';

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  //render blog posts
  const renderPosts = () => {
    return posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <BlogArticle key={node.fields.slug}>
              <Link style={{ boxShadow: `none`, color: '#353A4C' }} to={node.fields.slug}>
                <h3>{title}</h3>
              </Link>
          </BlogArticle>
        )
      });
  }

  return (
    <Container>
      <TopLine/>
      <Content>
        <Title>Tomas Velez</Title>
        <Hero>
          <HeroLeft>
            <HeroTitle>HELLO THERE</HeroTitle>
            <HeroDescription>
              I’m Tomás, front-end enthusiast from Chile 🇨🇱. I’m currently learning new technologies, writing some articles online and listening a lot of music.
            </HeroDescription>
          </HeroLeft>
          <HeroRight>
            <img src={profile}/>
            <ChatBubble>
              Yep, that’s me, in one of <br/>my trips to New York City.
            </ChatBubble>
          </HeroRight>
        </Hero>
        <Section>
          <SectionTitle>CURRENTLY</SectionTitle>
          <CurrentlyList>
            <li><strong>React Native developer</strong> at <a href='https://www.tenis-up.cl'>Tenis-Up</a></li>
            <li>Studying <strong>Computer Engineering</strong> in Universidad Católica del Norte</li>
            <li>Listening to <b>Hotel California</b> by <a href='#'>Eagles</a></li>
          </CurrentlyList>
        </Section>
        <Section>
          <SectionTitle>MY WORK</SectionTitle>
          <WorkList>
            <WorkItem/>
            <WorkItem/>
            <WorkItem/>
          </WorkList>
        </Section>
        <Section>
          <SectionTitle>BLOG</SectionTitle>
          {renderPosts()}
        </Section>
      </Content>
    </Container>
  )
}

export default BlogIndex

//styled-components
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TopLine = styled.div`
  display: flex;
  height: 18px;
  background-color: #469BFE;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 200px;
  padding-left: 200px;

  @media (max-width: 1280px) {
    padding-right: 120px;
    padding-left: 120px;
  }

  @media (max-width: 920px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const Title = styled.h1`
  font-family: Inter-Bold;
  font-size: 25.08px;
  color: #353A4C;
  letter-spacing: -0.31px;

  @media (max-width: 920px) {
    text-align: center;
  }
`

const Hero = styled.div`
  display: flex;
  padding-top: 40px;
  padding-bottom: 20px;

  @media (max-width: 920px) {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }
`

const HeroLeft = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 24px;

  @media (max-width: 1280px) {
    flex: 2;
  }

  @media (max-width: 920px) {
    align-items: center;
  }
`

const HeroRight = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: 24px;

  @media (max-width: 1280px) {
    flex: 1;
  }
`

const HeroTitle = styled.h2`
  font-family: Inter-BlackItalic;
  font-size: 56px;
  font-size: calc(36px + (56 - 36) * ((100vw - 300px) / (1600 - 300)));
  color: #353A4C;
  letter-spacing: -0.7px;

  @media (max-width: 920px) {
    text-align: center;
  }
`

const HeroDescription = styled.p`
  font-family: Inter-Regular;
  font-size: 18px;
  font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
  color: #A5A5BC;
  letter-spacing: -0.23px;
  line-height: 26px;

  @media (max-width: 920px) {
    text-align: center;
  }
`

const appear = keyframes`
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
    transform: translateY(60%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ChatBubble = styled.div`
  background: #008EFF;
  background-image: linear-gradient(48deg, #FF456E 0%, #469BFE 100%);
  border-radius: 32px 32px 32px 0;
  padding-right: 32px;
  padding-left: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: Inter-Medium;
  font-size: 1rem;
  color: #FFFFFF;
  letter-spacing: -0.2px;
  position: absolute;
  margin-top: 56px;
  line-height: 24px;
  right: 280px;

  @media (max-width: 1280px) {
    font-size: 14px;
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
    right: 180px;
    line-height: 20px;
    margin-top: 64px;
  }

  @media (max-width: 920px){
    visibility: hidden;
  }

  animation: ${appear} 1.4s ease ;
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
`

const SectionTitle = styled.h3`
  font-family: Inter-Bold;
  font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
  color: #D0D0DA;
  letter-spacing: 2.4px;
`

const CurrentlyList = styled.ul`
  list-style-type: none;
  
  @media (max-width: 768px){
    margin: 0;
  }

  li {
    font-family: Inter-Medium;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #353A4C;
    letter-spacing: -0.23px;
    display: flex;
    align-items: center;

    strong {
      margin-left: 4px;
      margin-right: 4px;
    }

    b {
      margin-left: 4px;
      margin-right: 4px;
    }

    a {
      color: #FD456F;
      margin-left: 4px;
      margin-right: 4px;
    }
  }


`

const WorkList = styled.div`
  display: flex;
  flex: 1;
`

const WorkItem = styled.div`
  display:inline;
  height: 320px;
  width: 240px;
  background-color: #F9F9FB;
  margin-right: 20px;
`

const BlogArticle = styled.article`
  margin-bottom: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-left: -16px;
  padding-left: 16px;
  border-radius: 8px;
  transition-duration: 400ms;

  h3 {
    margin: 0;
    font-family: Inter-Medium;
    font-weight: normal;
    font-size: 18px;
    color: #353A4C;
    line-height: 24px;
  }

  &:hover {
    background-color: #F9F9FB;
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
